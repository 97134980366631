<template>
  <div>
    <rxNavBar v-if="androidFlg == false" title="加班调休" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="加班调休"></rxNavBar>
    <!--        搜索框/状态-->
    <div class="head">
      <div class="searchDiv">
        <div class="search">
          <input placeholder="姓名/手机号/证件号" v-model="searchValue" @keyup.enter="search">
        </div>
      </div>

      <div style="display:flex;justify-content:space-around;">

         <!--            下拉-->
      <div class="downMenu" @click="showPicker = true">
        <div class="downMenuValue">{{ columns[number] ? columns[number].dictionaryTitle : '待我审的' }}</div>
        <div :class="showPicker ? 'uparrows' : 'arrows'"></div>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" :default-index="number" value-key="dictionaryTitle" @cancel="showPicker = false"
                    @confirm="onConfirm"/>
      </van-popup>

      <div class="downMenu" @click="dayPicker = true">
        <div class="downMenuValue">{{ dayColumns[dayNumber] ? dayColumns[dayNumber].dictionaryTitle : '全部' }}</div>
        <div :class="dayPicker ? 'uparrows' : 'arrows'"></div>
      </div>
      <van-popup v-model="dayPicker" position="bottom">
        <van-picker show-toolbar :columns="dayColumns" :default-index="dayNumber" value-key="dictionaryTitle" @cancel="dayPicker = false"
                    @confirm="onDayConfirm"/>
      </van-popup>


      </div>
     


    </div>
    <div class="blank-weight"></div>
    <div class="reviews">
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" v-if="orders.length!=0">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="onLoad"
        >
          <div class="review" v-for="(item,index) in orders" :key="index">
            <div :class="'top-title top-title-'+item.approvalStatus">
              {{ item.approvalStatusdescription }}
            </div>
            <div class="vacationForm">
              <van-row>
                <van-col span="12" class="t_td"><span class="text">申请人:</span>{{ item.addstaffName }}</van-col>
                <van-col span="12" class="t_td"><span class="text">审批人:</span>{{ item.approvalStaff }}</van-col>
              </van-row>
              <van-row>
                <van-col span="12" class="t_td"><span class="text">类型:</span>{{ item.dataTypeDescription }}</van-col>
                <van-col span="12" class="t_td"><span class="text">申请时间:</span>{{ item.addTimeStr }}</van-col>
              </van-row>
              <van-row>
                <van-col span="12" class="t_td" v-if="item.dataType == '1'"><span class="text">加班时间:</span>{{ item.expectedTimeStr }}</van-col>
                <van-col span="12" class="t_td" v-if="item.dataType == '2'"><span class="text">调休时间:</span>{{ item.expectedTimeStr }}</van-col>
                <van-col span="12" class="t_td"><span class="text">审批节点:</span>{{ item.approvalStatusdescription }}</van-col>
              </van-row>
              <van-row>
                <van-col span="24" class="t_td"><span class="text">部门:</span>{{ item.departmentName }}</van-col>
              </van-row>
               <van-row>
                <van-col span="24" class="t_td"><span class="text">事由:</span>{{ item.remarks }}</van-col>
              </van-row>

            </div>
            <div class="line"></div>

            <div class="bottom">
              <div class="look" v-if="item.approvalStatus != '4' && item.approvalStatus != '5' && item.approvalStatus != '6' && item.addStaff_id == staffId"
                   @click="revoke(item)">
                   撤销
              </div>
                <div class="check" v-if="item.approvalStatus == '1' && item.approvalStatus != '4' && item.approvalStatus != '5' && item.approvalStatus != '6' && item.seniorStaff_id == staffId && checkAuthList(authButtonsList,'seniors')"
                    @click="approve(item)">
                    审批
               </div>
               <div class="check"
                    @click="detail(item)">
                    详情
               </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <common-empty v-if="isEmptuyFlag"></common-empty>
      <div style="height: 70px"></div>
      <div class="sub_btn">
      <van-button  class="saveButton_Work" size="large"
       @click="saveDepartApply('work')">加班
            </van-button>

       <van-button  class="saveButton_Rest" size="large" style="margin-top:10px;"
       @click="saveDepartApply('rest')">调休
            </van-button>     
      </div>
    </div>
    <van-popup class="van-popup--bottom" v-model="acceptedShow" position="bottom" :style="{ height: '230px' }" >
      <div class="popMaincontant">

        <div class="part-inputpart-row">
            <span class="otherExpenses-row-enablenon part-inputpart-row-header">输入撤销原因</span>
            <span class="smallgraytext">(必填)</span>
        </div>
        <van-field
                  v-model="revokeRemarks"
                  autosize
                  type="textarea"
                  class="mes_body"
                  rows="3"
              />
        <div :class="{'part':true,'part-button':true,'part-button-enabled':checkCanSave()}" @click="revokeSave">
                提交
            </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {checkAndroid, checkAuthList, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,
  queryBaseData,
  queryWorkovertimecompensationMob,
  cancelWorkovertimecompensationMob
} from "../../../getData/getData";
import {
  Button,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search,
  Field,
  Row,
  Col
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar.vue";

export default {
  name: "staffEntry",
  components:{
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data(){
    return{
      androidFlg:'',
      authButtonsList:[],
      refreshing: false,
      loading : true,
      finished: false,
      noContent:false,
      isEmptuyFlag: false,
      page:{currentPage:1,numberPage:10,},
      orders:[],
      count:0,
      //搜索/状态
      searchValue:'',
      showPicker:false,
      dayPicker:false,
      columns:[
        {
            dictionaryTitle:"待我审的",
            dictionaryValue:"0"
        },
        {
           dictionaryTitle:"我已审批",
           dictionaryValue:"1" 
        },
        {
           dictionaryTitle:"我发起",
           dictionaryValue:"2" 
        }
      ],
      number:0,
      dayColumns:[
        {
            dictionaryTitle:"全部",
            dictionaryValue:"0"
        },
        {
           dictionaryTitle:"加班",
           dictionaryValue:"1" 
        },
        {
           dictionaryTitle:"调休",
           dictionaryValue:"2" 
        }
      ],
      dayNumber:0,
      revokeRemarks: '',
      acceptedShow: false,
      rowDataid: '',
      staffId: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
    }
    next()
  },
  created() {
    this.getStaffFuntionModelList()
    
  },
  mounted() {
    this.checkPhoneorMobel()
    // this.initDicData()
    this.getStaffFuntionModelList()
    this.finished = false;
    this.loading = true;
    this.refreshing = false
  },
  activated() {
    this.staffId = getStaffId();
    this.number = 0; 
    this.dayNumber = 0; 
    this.page.currentPage = 1;
    this.orders=[]
    this.onLoad();
  },
  methods:{
     checkCanSave(){
                let that = this
                if(that.revokeRemarks == '') {
                    return false
                } else {
                    return true
                }
            },
    revokeSave() {
        if(this.revokeRemarks == ''){
            return
        }

      Dialog.confirm({
        title: '友情提示',
        message: '确定撤回吗？',
      })
      .then(() => {
        let that = this;
        let initData = {};
        initData.staff_id = getStaffId();
        initData.id = that.rowDataid
        initData.retractReason = that.revokeRemarks
        cancelWorkovertimecompensationMob(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            responseUtil.alertOK(that)
            that.finished = false;
            that.orders = []
            that.page =  {currentPage:1,numberPage:3}
            that.loading = true;
            that.refreshing = false
            that.noContent = false
            that.acceptedShow =false
            that.onLoad();
          })
        })
      }).catch(() => {
      })
    },
    saveDepartApply(type,val) {
         this.$router.push({
            name:'workOrrestEdit',
            query:{
              type: type
            }
          });
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = []
      this.page =  {currentPage:1,numberPage:3}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.noContent = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    search(){
      if(this.searchValue!=''){
        localStorage.setItem('searchValue',JSON.stringify(this.searchValue))
      }else{
        localStorage.removeItem('searchValue')
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false
      this.loading = true
      this.noContent = false
      this.onLoad()
    },
    onConfirm(value, index){
      if(value){
        localStorage.setItem('currentLabel', JSON.stringify(index))
        this.number = index
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.showPicker = false
      this.loading = true;
      this.noContent = false
      this.onLoad()
    },
    onDayConfirm(value, index){
      if(value){
        localStorage.setItem('currentLabel', JSON.stringify(index))
        this.dayNumber = index
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.dayPicker = false
      this.loading = true;
      this.noContent = false
      this.onLoad()
    },
    onLoad() {
      let that = this
      let queryData = {
        currentPage: that.page.currentPage,
        numberPage: that.page.numberPage,
        staff_id: getStaffId(),
        myApproval: this.number,
        dateType: this.dayNumber,
        searchValue: this.searchValue,
      }

       queryWorkovertimecompensationMob(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          let list=response.data.data.data.result?response.data.data.data.result:[]
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          console.log("that.orders:",that.orders)
          if(that.refreshing){
            that.refreshing = false
          }
          // 加载状态结束
          that.loading = false;
          that.page.currentPage++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          // 判断数据是否为空，空则显示空状态图
          if (that.orders.length == 0) {
            that.isEmptuyFlag = true;
          } else {
            that.isEmptuyFlag = false;
          }

          if (1 == that.page.currentPage && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
          }
        })
      })
    
    },

    detail(value){
     value["enterType"] = "detail"
      this.$router.push({
        name:'workOrrestDetail',
        query:{
          ...value
        }
      });
    },
    revoke(item){
        console.log("item",item)
      this.revokeRemarks = ''
      this.rowDataid = item.id
      this.acceptedShow = true
      
    },
    approve(value){
      value["enterType"] = "approve"
      this.$router.push({
        name:'workOrrestDetail',
        query:{
          ...value
        }
      });
    },



    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'workOrRest_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
          console.log(that.authButtonsList,"that.authButtonsList=====")
        })
      })
    },
    checkAuthList,
    //初始化字典数据
    initDicData: function () {
      const that = this
      let initData = {}
      initData.fdName = [ 'RESUMESTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.RESUMESTATUSMAP
          for (let i = 0; i < list.length; i++) {
            that.columns.push(list[i])
          }
          console.log("columns",that.columns)
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
*{
  padding: 0;
  margin: 0;
}

//搜索+选择
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
  //搜索框
  .searchDiv {
    width: 100%;
    height: 40px;

    .search {
      width: 90%;
      height: 40px;
      margin: 10px auto;
      border-radius: 20px;
      background-color: #eeeeee;
      display: flex;

      input {
        width: 80%;
        background-color: #eeeeee;
        border: none;
        font-size: 15px;
      }
    }

    .search:before {
      content: '';
      display: block;
      background: url("../../../assets/images/search.png") no-repeat center center;
      background-size: 50% 50%;
      width: 40px;
      height: 40px;
    }

    input::placeholder {
      font-size: 15px;
    }
  }
  //下拉框
  .downMenu {
    height: 28px;
    width: 20%;
    margin: 15px 15px 0;
    line-height: 40px;
    display: flex;
    align-items: center;
    .downMenuValue {
      /*width: 14%;*/
      font-size: 14px;
      color: #FF5D3B;
    }

    .arrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: black transparent transparent transparent;
      margin-top: 1px;
      margin-left: 8px;
    }

    .uparrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: transparent transparent black transparent;
      margin-bottom: 6px;
      margin-left: 8px;
    }
  }
}

.blank-weight {
  height: 93px;
}
//列表
.reviews {
  overflow: hidden;
  position: relative;
}
.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title{
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}

.top-title-0,.top-title-1,.top-title-2,.top-title-3{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-1,.top-title-2{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-3,.top-title-4{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}

.top-title-5{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-6{
  background: linear-gradient(to right,  #7cc7ff, #3596fd);
}

/*入职表*/
.vacationForm {
  /*height: 168px;*/
  width: 315px;
  margin-left: 15px;
//   margin-bottom: 15px;
  margin-top: 10px;
  /*line-height: 30px;*/
}

.vacationForm div {
  line-height: 20px;
}

/*标题*/
.vacationType {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

/*时间*/
.start_endTime {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}
/*行信息标题label*/
.text {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
  /*letter-spacing: 1px;*/
}
/*行信息内容*/
.content {
  font-size: 13px;
  color: #9a9a9a;
}
.line {
  width: 100%;
  border-bottom: 1px solid #D8D8D8;
}
/*    下部按钮*/
.bottom{
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0.3rem 0;
}

.check,.look{
  /*float: right;*/
  //margin-top: 0.2rem;
  margin-right: 0.5rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*按钮*/
.check{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  color: white;
}
.look{
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white,white),
  linear-gradient(to right ,#FFC274,#FF5D3B);
  background-clip: padding-box,border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
.saveButton_Work{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Rest{
  background: linear-gradient(to right,  #7cc7ff, #3596fd 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
//   margin: 0.1rem 0 0.3rem;

}
.sub_btn {
//   margin: 0.8rem auto 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 140px;
  background: #fff;
}
.part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        // background-color: #ffffff;
        margin-top: 15px;
    }
    .otherExpenses-row-enablenon{
        margin-left: 10px;
    }
    .part-inputpart-row-header {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }
    .smallgraytext{
        color: #D8D8D8;
        font-size: 12px;
        margin-bottom: -3px;
    }
    .cancelOrderPopupTextValue{
        margin-bottom: 20px;
    }
    .part-button {
        height: 45px;
        border-radius: 10px;
        // background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 20px;
    }
    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }
    .van-popup--bottom {
      /*z-index: 2004;*/
      background-color: #f8f8f8;
    }
    .mes_body {
        border-radius: 10px;
        background-color: #ffff;
      }
      .popMaincontant {
        width: 92%;
        margin: 0 auto;

      }
    .t_td {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
      }
      .t_td_1 {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding-left: 10px;
      } 
</style>